import React, { ReactNode } from "react";
import CookieConsentView from "../CookieConsentView";
import NotificationView from "../NotificationView";
import "./LayoutBase.scss";

const LayoutBase = (
	{
		children,
		header,
		menu,
	}: {
		children: ReactNode,
		header: ReactNode,
		menu?: ReactNode,
	},
) => (
	<>
		<NotificationView/>
		<CookieConsentView/>
		{header}
		{menu}
		<main>{children}</main>
	</>
);

export default LayoutBase;
