import classNames from "classnames";
import { observer } from "mobx-react";
import React from "react";
import { useApp } from "../App";
import { GameButtonStyleType } from "./game/GameButton";
import LoginBtn from "./LoginBtn";
import Styles from "./LoginContainer.module.scss";
import LogoutBtn from "./shared/LogoutBtn";
import UserNameView from "./UserNameView";

export enum LoginContainerStyle
{
	HEADER = 0,
	MENU = 1,
}

function getStyleClass(style: LoginContainerStyle)
{
	switch (style) {
		case LoginContainerStyle.HEADER:
			return Styles.header;
		case LoginContainerStyle.MENU:
			return Styles.menu;
	}
}

const LoginContainerView = observer((
	{
		styleType,
	}: {
		styleType: LoginContainerStyle,
	}) =>
{
	const app = useApp();

	const styleClass = getStyleClass(styleType);

	let element: JSX.Element;
	if (app.store.logined) {
		element =
			<div className={classNames(Styles.container, styleClass)}>
				<UserNameView/>
				<div className={Styles.spacer}/>
				<LogoutBtn/>
			</div>;
	} else {
		element = <LoginBtn style={GameButtonStyleType.SMALL}/>;
	}

	return (
		<>
			{element}
		</>
	);
});

export default LoginContainerView;
