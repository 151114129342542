import React from "react";
import { ReactNode } from "react";
import InternalLink from "../game/InternalLink";
import Styles from "./HeaderLink.module.scss";

const HeaderInternalLink = (
	{
		children,
		href,
	}: {
		children: ReactNode,
		href: string,
	},
) => (
	<InternalLink
		href={href}
		className={Styles.headerLink}
	>
		{children}
	</InternalLink>
);

export default HeaderInternalLink;