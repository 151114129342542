import React from "react";
import CookieConsent from "react-cookie-consent";
import { L } from "../l10n/L10n";

const CookieConsentView = () => (
	<CookieConsent
		location="bottom"
		buttonText={L.cookieConsect.button}
		cookieName="CookieConsent"
		style={{background: "#2B373B", fontFamily: "Shanti"}}
		buttonStyle={{color: "#03050a", fontFamily: "Shanti", fontSize: "14px", fontWeight: "bold"}}
		expires={150}

	>
		{L.cookieConsect.text}
	</CookieConsent>
);

export default CookieConsentView;