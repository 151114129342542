import React, { ReactNode } from "react";
import Styles from "./HeaderContainer.module.scss";

const HeaderContainer = ({children}: {children: ReactNode}) => (
	<header>
		<div className={Styles.header}>
			<nav className={Styles.container}>
				{children}
			</nav>
		</div>
	</header>
);

export default HeaderContainer;